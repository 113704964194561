.cardContainer {
    margin: auto;
    height: 250px;
    width: 50%;
    /* border: lightgray solid; */
    border-width: 1px;
    box-shadow: 0px 2px 6px -2px;
    font-family: 'Darker grotesque', sans-serif;
    font-weight: 400;
    border-radius: 5px;
}

.cardList {
    margin: auto;
}

.projectPage {
    height: 100%;
}

.projectSpacer {
    height: 150px;
    width: 100%;  
    padding-top: 15px;
}
.projectRightSpacer {
    position: relative;
}

.projectLeftSpacer {
    position: relative;
}

.detailsParagraph {
    justify-content: center;
    font-size: 16pt;
}
.projectCard {
    height: 130px;
    width: 50%;
    border: solid white; 
    margin: auto;
    border-radius: 10px;
    border-width: 2px;
    box-shadow: 0px 2px 10px -2px black;
}

@media only screen and (max-width: 700px) {
    .projectCard {
        width: 80%;
    }
}

.detailsTitle {
    font-size: 32pt;
    font-weight: 800;
}

.projectCard:hover{
    cursor: pointer;
}

.projectLogo {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 10px;
    width: 120px;
    height: 120px;
    background-color: white;
    float: left;
}
.projectInfoHeader {
    font-family: 'Darker grotesque', sans-serif;
    font-weight: 800;
    text-align: left;
    font-size: 20pt;
    color: white; 
}

@media only screen and (max-width: 500px) {
    .projectInfoHeader {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        font-size: 22pt;
        margin-top: -5px;
        margin-right: 5px;
        text-align: center;
        
    }
}

.projectInfoText {
    font-family: 'Darker grotesque', sans-serif;
    font-weight: 400;
    text-align: left;
    font-size: 14pt;
    color: white;  
}

@media only screen and (max-width: 700px) {
    .projectInfoText {
        display: none;
    }
}

.projectImg {
    max-width: 120px;
    max-height: 120px;
}

.detailsImage > img {
    align-content: center;
    max-width: 100%;
    max-height: 100%;
    border-radius: 15px;
    margin-top: -20px;
    
}
