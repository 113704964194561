.appBar {
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 700px) {
    .appBar {
        height: 40px;
    }
}
.navTitle {
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 800;
    font-size: 30pt;
    float: left;
    margin-left: 5%;
}

@media only screen and (max-width: 700px) {
    .navTitle {
        font-size: 22pt;
    }
}

@media only screen and (max-width: 500px) {
    .navTitle {
        font-size: 18pt;
    }
}

.navButton {
    font-family: 'Darker grotesque', sans-serif;
    font-weight: 400;
    font-size: 16pt;
    float: right;
    margin-right: 5%;
    margin-top: 15px;
    bottom: 0px;
}

@media only screen and (max-width: 700px) {
    .navButton {
        font-size: 14pt;
        margin-top: 8px;
    }
}

@media only screen and (max-width: 500px) {
    .navButton {
        font-size: 12pt;
        margin-top: 6px;
    }
}

.link {
    color: black;
    text-decoration: none;
}

.spacer {
    height: 20px;
    width: 100%;
    background-color: black;
}

.navButton:hover{
    cursor: pointer;
}
